import { Button, IconButton, ThemeColor } from '@bpinternal/ui-kit'
import { Badge, Flex, Text, Tooltip } from '@radix-ui/themes'
import { useParams } from '@tanstack/react-router'
import {
  Boxes,
  ChartPie,
  ChartSpline,
  ChevronsUpDown,
  FileText,
  House,
  LayoutPanelLeft,
  MessageSquare,
  MessageSquareReply,
  MessagesSquare,
  Plus,
  Radio,
  Settings,
  SquareTerminal,
  Table,
  TriangleAlert,
  UsersRound,
  Variable,
} from 'lucide-react'
import { match, P } from 'ts-pattern'
import { Navbar, Boundary, BotSelectorMenu, BotIcon, type NavbarItem } from '~/componentsV2'
import { Icon } from '~/elementsv2'
import { createBotPrompt } from '~/features/bots/services/createBotPrompt'
import { NotificationBadge } from '~/features/issues/componentsV2'
import { useCreateBot, useCurrentRouteId } from '~/hooks'
import { useQuery } from '~/services'
import { useLocalStore } from '~/stores'
import { cn } from '~/utils'

type Props = { className?: string; expanded?: boolean }

export const SideNav = ({ className, expanded, ...props }: Props) => {
  const { workspaceId, botId } = useParams({ strict: false })

  const routeId = useCurrentRouteId()

  const workspaceNavbarItems: NavbarItem[] = [
    { type: 'link', text: 'Home', leadingIcon: House, to: '/workspaces/$workspaceId/home' },
    { type: 'link', text: 'Your Integrations', leadingIcon: Boxes, to: '/workspaces/$workspaceId/integrations' },
    { type: 'link', text: 'Usage', leadingIcon: ChartPie, to: '/workspaces/$workspaceId/usage' },
    { type: 'link', text: 'Settings', leadingIcon: Settings, to: '/workspaces/$workspaceId/settings' },
    { type: 'separator' },
  ].map((item) => {
    return { ...item, active: item?.to && routeId?.includes(item.to) } as NavbarItem
  })

  const botNavbarItems: NavbarItem[] = [
    {
      type: 'link',
      text: 'Overview',
      leadingIcon: LayoutPanelLeft,
      to: '/workspaces/$workspaceId/bots/$botId/overview',
    },
    {
      type: 'link',
      text: 'Webchat',
      leadingIcon: MessageSquare,
      to: '/workspaces/$workspaceId/bots/$botId/webchat',
    },
    { type: 'link', text: 'Logs', leadingIcon: SquareTerminal, to: '/workspaces/$workspaceId/bots/$botId/logs' },
    {
      type: 'link',
      text: 'Conversations',
      leadingIcon: MessagesSquare,
      to: '/workspaces/$workspaceId/bots/$botId/conversations',
    },
    {
      type: 'link',
      text: 'Users',
      leadingIcon: UsersRound,
      to: '/workspaces/$workspaceId/bots/$botId/users',
    },
    {
      type: 'link',
      text: 'Files',
      leadingIcon: FileText,
      to: '/workspaces/$workspaceId/bots/$botId/files',
    },
    { type: 'link', text: 'Analytics', leadingIcon: ChartSpline, to: '/workspaces/$workspaceId/bots/$botId/analytics' },
    { type: 'link', text: 'Events', leadingIcon: Radio, to: '/workspaces/$workspaceId/bots/$botId/events' },
    {
      type: 'link',
      text: 'Tables',
      leadingIcon: Table,
      to: '/workspaces/$workspaceId/bots/$botId/tables',
      trailingItem: (
        <Badge color="green" variant="outline">
          New
        </Badge>
      ),
    },

    {
      type: 'link',
      text: 'Issues',
      leadingIcon: TriangleAlert,
      trailingItem: (
        <Boundary loader={null} onError={() => null}>
          <NotificationBadge />
        </Boundary>
      ),
      to: '/workspaces/$workspaceId/bots/$botId/issues',
    },
    {
      type: 'link',
      text: 'Configuration variables',
      leadingIcon: Variable,
      to: '/workspaces/$workspaceId/bots/$botId/configuration-variables',
    },
    {
      type: 'link',
      text: 'Human in the loop',
      leadingIcon: MessageSquareReply,
      to: '/workspaces/$workspaceId/bots/$botId/hitl',
    },
  ].map((item) => {
    return { ...item, active: item?.to && routeId?.includes(item.to) } as NavbarItem
  })

  const lastBotId = useLocalStore((state) => state.getLastActiveBotId(workspaceId))
  const navBotId = botId ?? lastBotId
  const { data: bots, isFetching: isFetchingBots } = useQuery('workspaces_/$workspaceId_/bots_', {
    workspaceId: workspaceId ?? '',
  })
  const { mutate: createBot, isPending } = useCreateBot(workspaceId ?? '')

  const { data: bot, isFetching: isFetchingBot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', {
    workspaceId: workspaceId ?? '',
    botId: navBotId ?? '',
  })

  const hasBots = (bots?.length ?? 0) > 0
  const isFetching = isFetchingBots || isFetchingBot

  return (
    <Navbar
      items={[
        ...workspaceNavbarItems,
        {
          type: 'custom',
          content: isFetching ? null : hasBots ? (
            <BotSelectorMenu bots={bots ?? []}>
              <Flex gap={'2'} px={'2'} py={'2'} className={'rounded hover:bg-gray-3'}>
                {match({ bot, expanded })
                  .with({ bot: undefined, expanded: true }, () => (
                    <>
                      <Text color="gray" size={'1'}>
                        Select a bot...
                      </Text>
                      <Icon color="gray" icon={ChevronsUpDown} />
                    </>
                  ))
                  .with({ bot: undefined, expanded: false }, () => (
                    <Tooltip content={'Select a bot...'} side="right">
                      <Icon size="2" color="gray" icon={ChevronsUpDown} />
                    </Tooltip>
                  ))
                  .with({ bot: P.not(undefined), expanded: true }, ({ bot: { id, name } }) => (
                    <>
                      <BotIcon id={id} size="1" />
                      <Text color="gray" size={'1'}>
                        {name}
                      </Text>
                      <Icon className="ml-auto" color="gray" icon={ChevronsUpDown} />
                    </>
                  ))
                  .with({ bot: P.not(undefined), expanded: false }, ({ bot: { id, name } }) => (
                    <Tooltip content={name} side="right">
                      <div className="-m-0.5">
                        <BotIcon id={id} size="2" />
                      </div>
                    </Tooltip>
                  ))
                  .otherwise(() => null)}
              </Flex>
            </BotSelectorMenu>
          ) : expanded ? (
            <Button
              variant="dashed"
              color={ThemeColor}
              onClick={() => void createBotPrompt().then(createBot)}
              loading={isPending}
            >
              Create Bot
            </Button>
          ) : (
            <Tooltip content={'Create Bot'} side="right">
              <IconButton
                icon={Plus}
                size={'1'}
                color={ThemeColor}
                className="mx-auto size-7"
                variant="dashed"
                onClick={() => void createBotPrompt().then(createBot)}
                loading={isPending}
              />
            </Tooltip>
          ),
        },
        ...(bot?.id ? botNavbarItems : []),
      ]}
      botId={navBotId}
      workspaceId={workspaceId}
      expanded={expanded}
      color="gray"
      orientation="vertical"
      className={cn('p-2', className)}
      {...props}
    />
  )
}
