import { createFileRoute, useRouter } from '@tanstack/react-router'
import { PageSection, Spinner } from '~/elementsv2'
import { Badge, Flex, Grid, Text } from '@radix-ui/themes'
import { Color } from '~/types'
import { ReactNode } from 'react'
import { cn, dollars } from '~/utils'
import { ClientReturn } from 'botpress-client'
import { billingPlan } from '~/features/billing/constants'
import { HiOutlineCheck } from 'react-icons/hi2'
import {
  type BillingVersion,
  COMMUNITY_PLAN_PRODUCT_ID,
  PLUS_PLAN_PRODUCT_ID,
  TEAM_PLAN_PRODUCT_ID,
  useBilling,
} from '~/features/usage/useBilling'
import { getQueryOptions } from '~/services'
import { useCheckout } from '~/hooks'
import { computeTotalCost } from '~/features/billing/utils'
import { useSuspenseQuery } from '@tanstack/react-query'
import { BillingReadonlyCallout } from '~/features/billing/componentsV2'
import { Card, Button, showConfirmationPrompt, ThemeColor } from '@bpinternal/ui-kit'

export const Route = createFileRoute('/workspaces/$workspaceId/settings/billing/plans')({
  component: Component,
})

function Component() {
  const { workspaceId } = Route.useParams()
  const billingProps = useBilling(workspaceId)
  const billingReadonly = billingProps.billingInfo?.billingReadonly

  //TODO: This spinner has been done the lazy way, it should be replaced with a skeleton loader
  return billingProps.isLoading ? (
    <Flex width={'100%'} align={'center'} justify={'center'} height={'9'}>
      <Spinner />
    </Flex>
  ) : (
    <>
      {billingReadonly && <BillingReadonlyCallout />}
      <PlanSelection {...billingProps} />
    </>
  )
}

type BillingProps = ReturnType<typeof useBilling>
const PlanSelection = (props: BillingProps) => {
  const { workspaceId } = Route.useParams()
  const navigate = useRouter().navigate

  //Avoid using cache since we can't invalidate it when customer changes plan in stripe customer portal
  const { plan } = useSuspenseQuery({
    ...getQueryOptions('workspaces_/$workspaceId_', { workspaceId }),
    queryKey: [],
    gcTime: 0,
    staleTime: 0,
  }).data

  const { setProductQuantity, productQuantityMap, promoCode, ...billingProps } = props

  const onUpdateSubscription = () => {
    void navigate({ to: '/workspaces/$workspaceId/settings/billing', params: { workspaceId } })
  }

  const { mutate: handleCheckout } = useCheckout(
    workspaceId,
    {
      productQuantityMap,
      promoCode,
      ...billingProps,
    },
    onUpdateSubscription
  )

  const teamPlanPrice = (productQuantityMap[TEAM_PLAN_PRODUCT_ID]?.productDetails.price.unit_amount ?? 0) / 100
  let discountedTeamPlanPrice: number | undefined = undefined
  if (promoCode?.coupon.appliesTo?.includes(TEAM_PLAN_PRODUCT_ID)) {
    discountedTeamPlanPrice = teamPlanPrice * (1 - promoCode.coupon.percentOff / 100)
  }

  const plusPlanPrice = (productQuantityMap[PLUS_PLAN_PRODUCT_ID]?.productDetails.price.unit_amount ?? 0) / 100
  let discountedPlusPlanPrice: number | undefined = undefined
  if (promoCode?.coupon.appliesTo?.includes(PLUS_PLAN_PRODUCT_ID)) {
    discountedPlusPlanPrice = teamPlanPrice * (1 - promoCode.coupon.percentOff / 100)
  }

  const billingReadonly = props.billingInfo?.billingReadonly

  const isLegacyPricing = props.billingVersion === 'v1' || props.billingVersion === 'v2'

  return (
    <PageSection title="Pricing plans" className="@container">
      <Grid
        gap={'6'}
        className={`${isLegacyPricing ? 'grid-cols-1 @3xl:grid-cols-3' : 'grid-cols-1 @lg:grid-cols-2 @3xl:grid-cols-4'}`}
      >
        <PlanCard
          plan="community"
          billingVersion={props.billingVersion}
          price="Free"
          action={
            <Button
              disabled={plan === 'community' || plan === 'enterprise' || billingReadonly}
              variant="outline"
              onClick={() => {
                setProductQuantity(COMMUNITY_PLAN_PRODUCT_ID, 1)
                setProductQuantity(TEAM_PLAN_PRODUCT_ID, 0)
                setProductQuantity(PLUS_PLAN_PRODUCT_ID, 0)
                void showConfirmationPrompt(
                  <Text>
                    After updating to the Pay-as-you-go plan, your new monthly bill will be
                    <Text weight={'bold'}> ${computeTotalCost(productQuantityMap, promoCode)}</Text> per month. Are you
                    sure you want to proceed?
                  </Text>,
                  {
                    confirmLabel: 'Update plan',
                    title: 'Do you want to proceed?',
                  }
                ).then(() => {
                  handleCheckout()
                })
              }}
            >
              {plan === 'community' ? 'Current plan' : 'Update plan'}
            </Button>
          }
        />
        {!isLegacyPricing && (
          <PlanCard
            color={plan !== 'enterprise' ? 'indigo' : undefined}
            plan="plus"
            billingVersion={props.billingVersion}
            price={
              <Flex gap={'3'} align={'baseline'}>
                <Flex gap={'1'} align={'baseline'}>
                  <Text>${dollars(discountedPlusPlanPrice ?? plusPlanPrice)}</Text>
                  <Text size={'2'} color="gray" weight={'regular'}>
                    /month
                  </Text>
                </Flex>
                {discountedPlusPlanPrice && (
                  <>
                    <Text size={'2'} color="gray" weight={'regular'} className="line-through">
                      ${dollars(plusPlanPrice)}/month
                    </Text>
                  </>
                )}
              </Flex>
            }
            action={
              <Button
                disabled={plan === 'plus' || plan === 'enterprise' || billingReadonly}
                onClick={() => {
                  setProductQuantity(PLUS_PLAN_PRODUCT_ID, 1)
                  setProductQuantity(TEAM_PLAN_PRODUCT_ID, 0)
                  setProductQuantity(COMMUNITY_PLAN_PRODUCT_ID, 0)
                  void showConfirmationPrompt(
                    <Text>
                      After switching to the Plus plan, your new monthly bill will be
                      <Text weight={'bold'}> ${dollars(computeTotalCost(productQuantityMap, promoCode))}</Text> per
                      month. Are you sure you want to proceed?
                    </Text>,
                    {
                      confirmLabel: 'Switch to Plus plan',
                      title: 'Do you want to proceed?',
                    }
                  ).then(() => {
                    handleCheckout()
                  })
                }}
              >
                {plan === 'plus' ? 'Current plan' : 'Buy plan'}
              </Button>
            }
          />
        )}

        <PlanCard
          color={plan !== 'enterprise' ? 'green' : undefined}
          plan="team"
          billingVersion={props.billingVersion}
          badge={plan !== 'enterprise' ? 'Best value' : undefined}
          price={
            <Flex gap={'3'} align={'baseline'}>
              <Flex gap={'1'} align={'baseline'}>
                <Text>${dollars(discountedTeamPlanPrice ? discountedTeamPlanPrice : teamPlanPrice)}</Text>
                <Text size={'2'} color="gray" weight={'regular'}>
                  /month
                </Text>
              </Flex>
              {discountedTeamPlanPrice && (
                <>
                  <Text size={'2'} color="gray" weight={'regular'} className="line-through">
                    ${dollars(teamPlanPrice)}/month
                  </Text>
                </>
              )}
            </Flex>
          }
          action={
            <Button
              disabled={plan === 'team' || plan === 'enterprise' || billingReadonly}
              onClick={() => {
                setProductQuantity(TEAM_PLAN_PRODUCT_ID, 1)
                setProductQuantity(COMMUNITY_PLAN_PRODUCT_ID, 0)
                setProductQuantity(PLUS_PLAN_PRODUCT_ID, 0)
                void showConfirmationPrompt(
                  <Text>
                    After upgrading to the Team plan, your new monthly bill will be
                    <Text weight={'bold'}> ${dollars(computeTotalCost(productQuantityMap, promoCode))}</Text> per month.
                    Are you sure you want to proceed?
                  </Text>,
                  {
                    confirmLabel: 'Upgrade to Team plan',
                    title: 'Do you want to proceed?',
                  }
                ).then(() => {
                  handleCheckout()
                })
              }}
            >
              {plan === 'team' ? 'Current plan' : 'Buy plan'}
            </Button>
          }
        />
        <PlanCard
          plan="enterprise"
          billingVersion={props.billingVersion}
          color={plan === 'enterprise' ? 'iris' : undefined}
          badge={plan === 'enterprise' ? 'Current plan' : undefined}
          price="Custom"
          action={
            <a href="https://botpress.com/contact-us" target="_blank" rel="noreferrer">
              <Button className="w-full" variant="outline">
                Contact us
              </Button>
            </a>
          }
        />
      </Grid>
    </PageSection>
  )
}

type PlanCardProps = {
  color?: Color
  plan: ClientReturn<'getWorkspace'>['plan']
  badge?: ReactNode
  price?: ReactNode
  action: ReactNode
  billingVersion: BillingVersion
}

const PlanCard = ({ color, action, price, plan, badge, billingVersion }: PlanCardProps) => {
  return (
    <Card data-accent-color={color ?? ThemeColor} className={cn('p-7', { 'border-2 border-accent-6': color })}>
      <Flex direction={'column'} gap={'4'}>
        <Flex justify={'between'}>
          <Text weight={'medium'} className={cn({ 'text-accent-11': color })}>
            {billingPlan[plan].name}
          </Text>
          {badge && <Badge>{badge}</Badge>}
        </Flex>
        <Text size={'1'} color="gray">
          {billingPlan[plan].description}
        </Text>
        <Text size={'5'} weight={'bold'}>
          {price}
        </Text>
        {action}
        <Flex direction={'column'} gap={'3'}>
          {billingPlan[plan].inclusions[billingVersion].map((feature) => (
            <Flex key={feature} align={'start'} gap={'2'}>
              <HiOutlineCheck className="h-5 flex-none text-accent-9" />
              <Text size={'2'} color="gray">
                {feature}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Card>
  )
}
