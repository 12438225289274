import { Flex, Text, Separator, Tooltip, SegmentedControl } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import { HiOutlineMail } from 'react-icons/hi'
import { HiOutlineGlobeAlt, HiOutlinePhone, HiOutlinePhoto, HiPhoto } from 'react-icons/hi2'
import { Icon, TextArea, TextInput, TextInputSlot, Spinner, Select } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { type WebchatIntegrationConfig } from '~/features/webchat/schemas'
import { useUploadFile, useWebchat } from '~/hooks'
import { queryClient } from '~/providers/ReactQuery'
import { getQueryKey, useSuspenseQuery } from '~/services'
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
import { useDropzone } from 'react-dropzone'
import { contentType } from 'mime-types'

import { nanoid } from 'nanoid'
import { Button, Callout, DialogFooter, showDialog } from '@bpinternal/ui-kit'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v2/general')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()

  const { plan } = useSuspenseQuery('workspaces_/$workspaceId_', { workspaceId }).data

  const { config, isPending, setConfig, updateWebchatIntegration } = useWebchat({
    botId,
    workspaceId,
  })

  const isBelowPlusPlan = plan === 'community'

  return (
    <>
      <ConfigSection title="Settings" description="Configurations for the webchat.">
        <Flex gap={'4'} direction={'column'}>
          <Flex gap={'4'} align={'center'}>
            <div
              className="group relative cursor-pointer"
              onMouseDown={() =>
                showDialog(
                  (props) => (
                    <AvatarFormDialog
                      {...props}
                      workspaceId={workspaceId}
                      botId={botId}
                      avatarUrl={config.botAvatarUrl ?? ''}
                      updateFormData={setConfig}
                    />
                  ),
                  { title: 'Bot Avatar' }
                )
              }
            >
              <div className="absolute inset-0 z-10 flex items-center justify-center rounded-full bg-gray-11 opacity-0 transition-opacity group-hover:opacity-70">
                <HiOutlinePhoto className="aspect-square h-5 text-white" />
              </div>
              {config.botAvatarUrl ? (
                <img
                  className="aspect-square h-11 max-w-none rounded-full object-cover"
                  src={config.botAvatarUrl}
                  alt="Bot Avatar"
                />
              ) : (
                <div className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-2">
                  <HiPhoto className="text-gray-9" />
                </div>
              )}
            </div>
            <TextInput
              label={'Bot Name'}
              placeholder="Your chatbot"
              value={config.botDisplayName}
              onChange={(e) => setConfig((prev) => ({ ...prev, botDisplayName: e.target.value }))}
              className="w-full"
            />
          </Flex>
          <TextArea
            label={'Description'}
            value={config.botDescription}
            onChange={(e) => setConfig((prev) => ({ ...prev, botDescription: e.target.value }))}
            placeholder="A brief description of your chatbot"
            className="w-full"
          />
          <TextInput
            label={'Composer placeholder'}
            value={config.botComposerPlaceholder}
            placeholder="Chat with bot"
            onChange={(e) => setConfig((prev) => ({ ...prev, botComposerPlaceholder: e.target.value }))}
          />
          <Flex direction={'column'} gap={'1'}>
            <Text size={'2'} color="gray">
              Branding
            </Text>
            <Tooltip
              open={isBelowPlusPlan ? undefined : false}
              content="This feature is only available on Plus plan or above."
            >
              <Select
                variant="soft"
                color="gray"
                className="w-fit"
                disabled={isBelowPlusPlan}
                value={!isBelowPlusPlan ? 'show' : config.showPoweredBy ? 'show' : 'hide'}
                onValueChange={(value) => setConfig((prev) => ({ ...prev, showPoweredBy: value === 'show' }))}
                items={[
                  { type: 'item', value: 'show', content: 'Show⚡ by Botpress' },
                  { type: 'item', value: 'hide', content: 'Hide ⚡ by Botpress' },
                ]}
              />
            </Tooltip>
          </Flex>
          <Flex direction={'column'} gap={'1'} className="w-fit">
            <Text size={'2'} color="gray">
              Allow user file upload
            </Text>
            <SegmentedControl.Root
              value={config.allowFileUpload ? 'enabled' : 'disabled'}
              onValueChange={(value) => {
                setConfig((prev) => ({ ...prev, allowFileUpload: value === 'enabled' }))
              }}
            >
              <SegmentedControl.Item value="enabled">Enabled</SegmentedControl.Item>
              <SegmentedControl.Item value="disabled">Disabled</SegmentedControl.Item>
            </SegmentedControl.Root>
          </Flex>
        </Flex>
      </ConfigSection>
      <Separator size={'4'} my={'4'} />
      <ConfigSection title="Contact" description="Contact information for your bot.">
        <Flex gap={'4'} direction={'column'}>
          <TextInput
            leading={<Icon color="gray" muted icon={HiOutlineMail} />}
            placeholder="example@example.com"
            value={config.descriptionEmailAddress?.link}
            onChange={(e) =>
              setConfig((prev) => ({
                ...prev,
                descriptionEmailAddress: { title: e.target.value, link: e.target.value },
              }))
            }
          >
            <TextInputSlot>
              <Icon color="gray" muted icon={HiOutlineMail} />
            </TextInputSlot>
          </TextInput>
          <TextInput
            leading={<Icon color="gray" muted icon={HiOutlinePhone} />}
            placeholder="+1 555 555 5555"
            value={config.descriptionPhoneNumber?.link}
            onChange={(e) =>
              setConfig((prev) => ({
                ...prev,
                descriptionPhoneNumber: { title: e.target.value, link: e.target.value },
              }))
            }
          />

          <TextInput
            leading={<Icon color="gray" muted icon={HiOutlineGlobeAlt} />}
            placeholder="https://example.com"
            value={config.descriptionWebsiteUrl?.link}
            onChange={(e) =>
              setConfig((prev) => ({ ...prev, descriptionWebsiteUrl: { title: e.target.value, link: e.target.value } }))
            }
          />
          <TextInput
            label={'Terms of service'}
            placeholder="https://example.com/terms"
            value={config.termsConditionsUrl?.link}
            onChange={(e) =>
              setConfig((prev) => ({ ...prev, termsConditionsUrl: { title: e.target.value, link: e.target.value } }))
            }
          />
          <TextInput
            label={'Privacy policy'}
            placeholder="https://example.com/privacy"
            value={config.privacyPolicyUrl?.link}
            onChange={(e) =>
              setConfig((prev) => ({ ...prev, privacyPolicyUrl: { title: e.target.value, link: e.target.value } }))
            }
          />
          <Button
            loading={isPending}
            onClick={() => {
              updateWebchatIntegration(config)
              void queryClient.invalidateQueries({
                queryKey: getQueryKey('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
                  workspaceId,
                  botId,
                }),
              })
            }}
            className="self-end"
          >
            <Text>Save</Text>
          </Button>
        </Flex>
      </ConfigSection>
    </>
  )
}

function AvatarFormDialog({
  workspaceId,
  botId,
  updateFormData,
  avatarUrl,
  close,
}: {
  avatarUrl: string
  botId: string
  close: () => void
  workspaceId: string
  updateFormData: React.Dispatch<React.SetStateAction<WebchatIntegrationConfig>>
}) {
  const [newAvatarUrl, setNewAvatarUrl] = useState<string | undefined>(avatarUrl ?? undefined)

  return (
    <Flex className="w-full" direction={'column'}>
      <AvatarForm workspaceId={workspaceId} botId={botId} avatarUrl={newAvatarUrl} setAvatarUrl={setNewAvatarUrl} />
      <DialogFooter
        onConfirm={() => {
          updateFormData((prevData) => ({ ...prevData, botAvatarUrl: newAvatarUrl }))
          close()
        }}
        onCancel={close}
      />
    </Flex>
  )
}

export type AvatarFormProps = {
  botId: string
  workspaceId: string
  avatarUrl?: string
  setAvatarUrl: (url: string | undefined) => void
}

function AvatarForm(props: AvatarFormProps) {
  const {
    mutateAsync: uploadFile,
    error,
    isPending,
  } = useUploadFile({ botId: props.botId, workspaceId: props.workspaceId })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const mimeType = contentType(acceptedFiles[0]?.type ?? '')
      uploadFile({
        key: `bot-${props.botId}-avatar-${nanoid(6)}`,
        content: acceptedFiles[0],
        accessPolicies: ['public_content'],
        contentType: mimeType ? mimeType : undefined,
        tags: { source: 'integration', integration: 'webchat' },
      }).then((file) => {
        props.setAvatarUrl(file?.file.url)
      })
    },
  })

  return (
    <div {...getRootProps()} className="w-full">
      <>
        <input {...getInputProps()} />
        <div className="relative flex min-h-[150px] items-center rounded-lg border-2 border-dotted border-gray-4 p-5">
          {isPending ? (
            <Flex justify={'center'} className="w-full">
              <Spinner size="5" />
            </Flex>
          ) : (
            <>
              {isDragActive && (
                <div className="bg-gray-1/80 absolute left-0 top-0 z-[2] flex h-full w-full flex-col items-center justify-center text-gray-11">
                  <ArrowDownOnSquareIcon className="mb-4 h-8 w-8 animate-bounce opacity-50" />
                </div>
              )}
              <>
                <div className="z-1 relative h-[120px] w-[120px]">
                  {props.avatarUrl && (
                    <>
                      {/* Fake mask */}
                      <div className="z-1 absolute left-0 top-0 h-full w-full rounded-sm">
                        <img className="absolute h-full w-full object-cover" src={props.avatarUrl} alt="Bot Avatar" />
                        <div className="absolute h-full w-full bg-black/50" />
                      </div>
                      <img
                        className="relative aspect-square h-full w-full max-w-none rounded-full border border-gray-4 bg-gray-1 object-cover"
                        src={props.avatarUrl}
                        alt="Bot Avatar"
                      />
                    </>
                  )}
                </div>
                <div className="mx-6 h-[96px] w-[1px] bg-gray-4" />
                <div className="flex flex-grow flex-col items-center">
                  <span className="text-sm text-gray-11">Drop image file here</span>
                  <span className="mb-2 mt-1 text-sm text-gray-10">or</span>
                  <Button variant="outline" size={'1'}>
                    Select File
                  </Button>
                </div>
              </>
            </>
          )}
        </div>
        {error && (
          <Callout className="mt-4 p-2" color="red">
            {error.message}
          </Callout>
        )}
      </>
    </div>
  )
}
